const myTheme = {
  breakpoints: ["576px", "768px", "992px", "1200px", "1400px"],
  fonts: {
    body: '"Montserrat", sans-serif',
    heading: '"Yeseva One", cursive',
    cursive: '"Reenie Beanie", cursive',
    button: '"Roboto",sans-serif',
  },
  colors: {
    text: "#7a7a7a",
    background: "#fff",
    heading: "#062e36",
    primary: "#ff4000",
    secondary: "#ffc93f",
    accent: "#f15b96",
    muted: "#7a7a7a",
  },
  fontSizes: [
    "1rem",
    "1.125rem",
    "1.525rem",
    "1.875rem",
    "2.250rem",
    "3rem",
    "3.75rem",
  ],
  lineHeights: {
    body: "1.5424",
    heading: "1.28",
    h1: "1.05",
    h2: "1.271",
    h3: "1.28",
    h4: "1.2",
    h5: "1.25",
    h6: "1.28",
    p: "1.625em",
  },
  links: {
    primary: {
      background:
        "linear-gradient(to right, #ffc93f 50%,#ff4000 50%) no-repeat scroll right bottom / 210% 100% #ff4000",
      color: "#ffffff",
      transition: "all 0.3s ease",
      fontSize: "1.25em",
      boxShadow: "none",
      textDecoration: "none",
      borderRadius: "31px",
      fontFamily: '"Roboto",sans-serif',
      appearance: "none",
      cursor: "pointer",
      display: "inline-block",
      verticalAlign: "top",
      whiteSpace: "nowrap",
      padding: "1.2em 3.1em",
      letterSpacing: "0",
      height: "auto",
      borderWidth: "0",
      boxSizing: "border-box",
    },
  },
  styles: {
    root: {
      fontSize: 0,
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "body",
      fontFamily: "body",
    },
    h1: {
      fontFamily: "heading",
      fontSize: 6,
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "h1",
    },
    h2: {
      fontFamily: "heading",
      fontSize: 5,
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "h2",
    },
    h3: {
      fontFamily: "heading",
      fontSize: 4,
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "h3",
    },
    h4: {
      fontFamily: "body",
      fontSize: 3,
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "h4",
    },
    h5: {
      fontFamily: "body",
      fontSize: 2,
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "h5",
    },
    h6: {
      fontFamily: "body",
      fontSize: 1,
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "h6",
    },
    p: {
      marginTop: "1.45em",
      marginBottom: "1.6em",
      lineHeight: "p",
    },
    ul: {
      marginTop: "1.45em",
      marginBottom: "1.6em",
    },
    ol: {
      marginTop: "1.45em",
      marginBottom: "1.6em",
    },
    blockquote: {
      marginTop: "1.45em",
      marginBottom: "1.6em",
    },
    img: {
      maxWidth: "100%",
      height: "auto",
      verticalAlign: "middle",
    },
  },
}

export default myTheme
