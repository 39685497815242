const smoothscroll = require("smoothscroll-polyfill")

smoothscroll.polyfill()
exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const hash = location.hash.replace(/^#(\d)/, "#\\3$1")
    const elem = hash && document.querySelector(`${hash}`)
    const item = elem && elem.offsetParent.offsetTop

    if (item) {
      window.scrollTo({
        top: item - mainNavHeight,
        behavior: "smooth",
      })
    }
  }

  return true
}
